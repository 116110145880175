const collapseFooter = () => {
  const footerContainer = document.querySelector('.footer-container');
  const footer = document.querySelector('.footer-accordion');
  const header = footer.querySelector('.accordion-header');
  const iconExpand = document.querySelector('.icon-expand');

  header.addEventListener('click', () => {
    iconExpand.classList.toggle('fa-plus');
    iconExpand.classList.toggle('fa-minus');
  });

  const collapse = () => {
    if (footer.offsetHeight > 60) {
      header.click();
    }
  }

  const detectOutside = (clickTarget) => {
    let target = clickTarget;
    do {
      if (target == footerContainer) {
        return;
      }
      target = target.parentNode;
    } while (target);
    collapse();
  }

  window.addEventListener('resize', event => {
    collapse();
  })

  window.addEventListener('click', event => {
    detectOutside(event.target);
  })

  window.addEventListener('touchstart', event => {
    detectOutside(event.target);
  })
}

export { collapseFooter }
