const modalFormError = () => {
  const allInputs = document.querySelectorAll('.form-group.required input')
  const allTextArea = document.querySelectorAll('.form-group.required textarea')

  const checkEmpty = (input) => {
    const errorDiv = document.createElement('div');
    errorDiv.classList.add('modal_form_inline_error');
    errorDiv.innerText = I18n.t('errors.input_required');

    if (input.value == '' && !input.nextSibling) {
      input.classList.add('modal_form_inline_error');
      input.insertAdjacentElement('afterend', errorDiv);
    } else if (input.value == '' && input.nextSibling) {
      input.classList.add('modal_form_inline_error');
    } else {
      input.classList.remove('modal_form_inline_error');
      if (input.nextSibling && input.nextSibling.className.includes('modal_form_inline_error')) {
        input.nextSibling.remove(input.nextSibling.selectedIndex);
      }
    }
  }

  const inputEvents = (input) => {
    input.addEventListener('keyup', event => {
      checkEmpty(input);
    });
    input.addEventListener('focusout', event => {
      checkEmpty(input);
    });
  }

  for (var i = allInputs.length - 1; i >= 0; i--) {
    inputEvents(allInputs[i]);
  }

  for (var i = allTextArea.length - 1; i >= 0; i--) {
    inputEvents(allTextArea[i]);
  }
}

export { modalFormError }
