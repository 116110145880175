const openPdf = () => {
  const pdfLink = document.querySelector('.footer-image');
  if (pdfLink) {
    pdfLink.addEventListener('click', (event) => {
      console.log('event :>> ', event.target);
      const url = event.target.getAttribute('data-path-to-asset');
      window.open(url, '_blank');
    });
  };
}

export { openPdf }
