const zoomImage = () => {
  const zoomPreviews = Array.from(document.getElementsByClassName('zoom-preview'));

  const zoomAdd = (e) => {
    const zoomImage = document.querySelector('.zoom-image');
    const imageURL = e.currentTarget.src
    zoomImage.classList.remove('zoom-image-hide');
    zoomImage.style.backgroundImage = `url(${imageURL})`;

    const x = e.offsetX / e.currentTarget.offsetWidth * 100
    const y = e.offsetY / e.currentTarget.offsetHeight * 100
    zoomImage.style.backgroundPosition = x + '% ' + y + '%';
  }

  const zoomRemove = (e) => {
    const zoomImage = document.querySelector('.zoom-image');
    zoomImage.classList.add('zoom-image-hide')
  }

  zoomPreviews.forEach(function(zoomPreviewImage) {
    zoomPreviewImage.addEventListener('mousemove', e => zoomAdd(e))
    zoomPreviewImage.addEventListener('mouseout', e => zoomRemove(e))
  })
}

export { zoomImage };
