const trixSetStyles = () => {
  const trixInputs = document.querySelectorAll('.trix-content');

  const heading1All = document.querySelectorAll('.trix-button--icon-heading-1')
  heading1All.forEach(function(heading1){
    heading1.innerHTML = '<i class="fas fa-heading"></i> 1'
  })
}

export { trixSetStyles };
