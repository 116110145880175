const navbarToggler = () => {
  const togglerButton = document.querySelector('.navbar-toggler');
  const togglerIcon = document.querySelector('.navbar-toggler-animated-icon');
  const togglerMenu = document.querySelector('#navbarNavDropdown');

  if (togglerButton) {
    togglerButton.addEventListener('click', event => {
      togglerIcon.classList.toggle('open-icon');
    });
  };
}

export { navbarToggler }
