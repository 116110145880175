import { initSelect2 } from '../plugins/init_select2';
import { sidebarTagScroll } from '../components/sidebar_tag_scroll';
import { zoomImage } from '../components/zoom_image';
import { masonryGridLayout } from '../components/masonry_grid_layout';
import { indexFilter } from '../components/index_filter';
import { sidebarHome } from '../components/sidebar_home';
import { sidebarArtworks } from '../components/sidebar_artworks';
import { preventDownload } from '../components/prevent_download';
// import { detectDevice } from '../components/detect_device';
import { setDimensions } from '../components/set_dimensions';
import { navbarToggler } from '../components/navbar_toggler';
import { modalFormError } from '../components/modal_form_error';
import { backToTop } from '../components/back_to_top';
import { collapseFooter } from '../components/collapse_footer';
import { collapseNavbar } from '../components/collapse_navbar';
import { imagePreviewSingle, imagePreviewMultiple, imageRemove } from '../components/image_preview';
import { trixSetStyles } from '../components/trix_set_styles';
import { editionsFilter } from '../components/editions_filter';
import { openPdf } from '../components/open_pdf';

// SET HEIGHT UNITS
setDimensions();

//TOGGLE BURGER MENU
const navbarContainer = document.querySelector('.navbar-container');
if (navbarContainer) {
  navbarToggler();
}

// PREVENT DOWNLOAD
const pageImages = document.getElementsByTagName('img');
const pageVideo = document.getElementsByTagName('video');
if (pageImages || pageVideo) {
  preventDownload();
}

// MODAL FORM ERROR
const modalForm = document.querySelector('.modal-body form');
if (modalForm) {
  modalFormError();
}

// SIDEBAR TAG SCROLL
const sidebarTagList = document.querySelector('.section-tag-list');
if (sidebarTagList) {
  sidebarTagScroll();
};

// BACK TO TOP BUTTON
const overallContainer = document.querySelector('.overall-container');
if (overallContainer) {
  backToTop();
}

// ZOOM IMAGE
const zoomPreview = document.querySelector('.zoom-preview');
if (zoomPreview) {
  zoomImage();
}

// MASONRY GRID
const masonryGrid = document.querySelector('.masonry-grid');
const regularGrid = document.querySelector('.regular-grid');
if (masonryGrid || regularGrid) {
  masonryGridLayout();
}

// INDEX FILTER
const indexToggles = document.querySelector('.sidebar-index-toggles');
if (indexToggles) {
  indexFilter();
}

// EDITIONS FILTER
const editionsToggles = document.querySelector('.sidebar-editions-toggles');
if (editionsToggles) {
  editionsFilter();
}

// SIDEBAR HOME
const homePage = document.querySelector('#home-image-carousel');
if (homePage) {
  sidebarHome();
}

// SIDEBAR ARTWORKS
const artworkShowPage = document.querySelector('.carousel-artwork');
if (artworkShowPage) {
  sidebarArtworks();
}

// COLLAPSE NAVBAR ON RESIZE / CLICK OUTSIDE
// COLLAPSE FOOTER ON RESIZE / CLICK OUTSIDE
const collapsibleFooter = document.querySelector('.footer-accordion');
if (collapsibleFooter && collapsibleFooter.offsetHeight > 0) {
  collapseNavbar();
  collapseFooter();
}

// SELECT2
const selectInput = document.querySelector('.select2');
if (selectInput) {
  initSelect2();
}

// FORM IMAGE PREVIEW
const imageUploadSingle = document.querySelector('.file-single');
const imageUploadMultiple = document.querySelector('.file-multiple');
if (imageUploadSingle) {
  imagePreviewSingle();
  imageRemove();
}
if (imageUploadMultiple) {
  imagePreviewMultiple();
  imageRemove();
}

// ACTION TEXT / TRIX
const trixInput = document.querySelectorAll('trix-editor');
if (trixInput[0]) {
  trixSetStyles();
}

// OPEN PDF
const pdfLink = document.querySelector('.footer-image');
if (pdfLink) {
  openPdf();
};
