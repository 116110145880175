import $ from 'jquery';
import 'select2';

const formatStateResult = (state) => {
  if (!state.id || !state.element.dataset.image) {
    return state.text;
  }
  const imageUrl = state.element.dataset.image
  const $state = $(
    '<span><img src="' + imageUrl + '" class="img-flag" /> ' + state.text + '</span>'
  );
  return $state;
};

const formatStateSelection = (state) => {
  if (!state.id || !state.element.dataset.image) {
    return state.text;
  }
  const imageUrl = state.element.dataset.image
  const $state = $(
    '<span><img class="img-flag" /> <span></span></span>'
  );
  $state.find("span").text(state.text);
  $state.find("img").attr("src", imageUrl);
  return $state;
};

const initSelect2 = () => {
  $('.select2').select2({
    closeOnSelect: false,
    templateResult: formatStateResult,
    templateSelection: formatStateSelection
  });
};

export { initSelect2 };
