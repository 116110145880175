const masonryGridLayout = () => {
  // RE-ARRANGE GRID
  const resizeGridItem = (item) => {
    const grid = document.querySelector('.masonry-grid');
    const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    const itemHeight = item.querySelector('.masonry-grid-item-content').getBoundingClientRect().height
    const rowSpan = Math.ceil((itemHeight + rowGap) / (rowHeight + rowGap));
    item.style.gridRowEnd = "span " + rowSpan;
  };

  const resizeAllGridItems = () => {
    const allItems = Array.from(document.getElementsByClassName("masonry-grid-item"));
    allItems.forEach(function(item) {
      resizeGridItem(item);
    })
  };

  // ADD LOADING WRAPPER
  const addLoading = () => {
    const loading = document.querySelector('.loading-wrapper');
    const grid = document.querySelector('.masonry-grid');
    const regularGrid = document.querySelector('.regular-grid');
    loading.classList.remove('d-none');
    if (grid) {
      grid.classList.add('grid-hide');
    } else if (regularGrid) {
      regularGrid.classList.add('grid-hide');
    }
  }

  // REMOVE LOADING WRAPPER
  const removeLoading = () => {
    const loading = document.querySelector('.loading-wrapper');
    const grid = document.querySelector('.masonry-grid');
    const regularGrid = document.querySelector('.regular-grid');
    loading.classList.add('d-none');
    if (grid) {
      grid.classList.remove('grid-hide');
    } else if (regularGrid) {
      regularGrid.classList.remove('grid-hide');
    }
  }

  // ALL IMAGES LOAD
  const imagesLoad = () => {
    let imageLoadCount = 0;
    const allImages = document.querySelectorAll('.masonry-media');

    const countCheck = () => {
      if (imageLoadCount == allImages.length) {
        resizeAllGridItems();
        removeLoading();
      };
    }

    for (var i = allImages.length - 1; i >= 0; i--) {
      if (allImages[i].complete) {
        imageLoadCount += 1
        countCheck();
      } else {
        addLoading();
        allImages[i].addEventListener('load', event => {
          imageLoadCount += 1
          countCheck();
        })
      };
    }
  };

  // CAROUSEL RESIZE
  const carouselResize = () => {
    const carouselAll = document.querySelectorAll('.carousel');
    for (var i = carouselAll.length - 1; i >= 0; i--) {
      carouselAll[i].addEventListener('click', event => {
        resizeAllGridItems();
        event.target.parentElement.parentElement.addEventListener('transitionend', event => {
          resizeAllGridItems();
        })
      });
    }
  };

  imagesLoad();
  carouselResize();
  // window.addEventListener('load', imagesLoad);
  window.addEventListener('resize', imagesLoad);
}

export { masonryGridLayout };
