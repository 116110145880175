const sidebarArtworks = () => {
  const carouselItems = Array.from(document.querySelector('.carousel-artwork').children);

  const detailsSidebar = document.querySelector('.carousel-artwork-details');
  const detailsFooter = document.querySelector('.carousel-artwork-details-footer');

  const allDetailsSidebar = Array.from(document.querySelector('.carousel-artwork-details').children);
  const allDetailsFooter = Array.from(document.querySelector('.carousel-artwork-details-footer').children);

  const refreshDetails = () => {
    let activeItem = document.querySelector('.active');
    let activeItemId = activeItem.getAttribute('data-target');
    let activeItemDetailsS = detailsSidebar.querySelector(`.id-${activeItemId}`);
    let activeItemDetailsF = detailsFooter.querySelector(`.id-${activeItemId}`);

    let otherDetailsSidebar = allDetailsSidebar.filter(n => ![activeItemDetailsS].includes(n))
    let otherDetailsFooter = allDetailsFooter.filter(n => ![activeItemDetailsF].includes(n))

    activeItemDetailsS.classList.remove('d-none')
    activeItemDetailsF.classList.remove('d-none')

    otherDetailsSidebar.forEach(function(detail) {
      detail.classList.add('d-none');
    });
    otherDetailsFooter.forEach(function(detail) {
      detail.classList.add('d-none');
    });
  };

  refreshDetails();

  const mutationObserver = new MutationObserver(refreshDetails)

  carouselItems.forEach(function(item) {
    mutationObserver.observe( item, { attributes: true } );
  })
};

export { sidebarArtworks };
