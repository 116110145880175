const setDimensions = () => {
  let windowHeight;
  let windowWidth;
  let navbarHeight;
  let footerHeight;
  let mainHeight;

  const resetVariables = () => {
    windowHeight = window.innerHeight;
    windowWidth = window.innerWidth;
    navbarHeight = document.querySelector('.navbar-container').getBoundingClientRect().height;
    footerHeight = document.querySelector('.footer-container').getBoundingClientRect().height;
    mainHeight = windowHeight - navbarHeight - footerHeight;
  }

  const calcWindowHeight = () => {
    document.querySelector(':root').setAttribute('style', `--vh:${windowHeight}px; --vw:${windowWidth}px; --mh:${mainHeight}px`)
  }

  const calcLastSectionHeight = (allItems) => {
    const sectionHeight = allItems[allItems.length - 1].getBoundingClientRect().height;
    let scrollHeight = 0

    if (sectionHeight < mainHeight) {
      scrollHeight = mainHeight - sectionHeight + footerHeight;
    }

    document.querySelector(':root').setAttribute('style', `--vh:${windowHeight}px; --vw:${windowWidth}px; --mh:${mainHeight}px; --sh:${scrollHeight}px`)
  }

  const checkMainSections = () => {
    resetVariables();
    const allItems = document.querySelectorAll('.main-section');
    if (allItems.length > 0) {
      calcLastSectionHeight(allItems);
    } else {
      calcWindowHeight();
    }
  }

  checkMainSections();
  window.addEventListener('load', checkMainSections);
  window.addEventListener('resize', checkMainSections);
}

export { setDimensions }
