const backToTop = () => {
  const overallContainer = document.querySelector('.overall-container');
  const backToTopButtonAll = document.querySelectorAll('.back-top-button');
  let backToTopButton;

  const findButton = () => {
    backToTopButton = backToTopButtonAll[0];
    // if (backToTopButtonAll[0].getBoundingClientRect().height != 0) {
    //   backToTopButton = backToTopButtonAll[0];
    // } else {
    //   backToTopButton = backToTopButtonAll[1];
    // }
  }

  const buttonEvents = () => {
    // desktop events
    backToTopButton.addEventListener('click', event => {
      navigateToTop();
    });
    // moblie events
    backToTopButton.addEventListener('touchstart', event => {
      backToTopButton.classList.remove('mobile-hover-fix');
    });
    backToTopButton.addEventListener('touchend', event => {
      backToTopButton.classList.add('mobile-hover-fix');
      navigateToTop();
    });
  }

  const navigateToTop = () => {
    overallContainer.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const showBackToTop = () => {
    if (overallContainer.scrollTop > 0) {
      backToTopButton.classList.remove('back-top-hide');
    } else {
      backToTopButton.classList.add('back-top-hide');
    }
  };

  findButton();
  buttonEvents();
  overallContainer.addEventListener('scroll', event => {
    showBackToTop();
  });
  window.addEventListener('resize', event => {
    findButton();
    buttonEvents();
  });
}

export { backToTop }
