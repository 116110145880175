const sidebarHome = () => {
  const carouselHome = document.querySelector('#carousel-home');
  let carouselItems;
  if (carouselHome) {
    carouselItems = Array.from(document.querySelector('#carousel-home').children);
  } else {
    carouselItems = Array.from(document.querySelectorAll('#carousel-fake'));
  }


  const allDetailsDesktop = document.querySelector('#carousel-details');
  const allDetailsMobile = document.querySelector('#carousel-details-mobile');
  const allDetailsArrayDesktop = Array.from(allDetailsDesktop.children);
  const allDetailsArrayMobile = Array.from(allDetailsMobile.children);

  let allDetails = allDetailsDesktop;
  let allDetailsArray = allDetailsArrayDesktop;

  const defineAllDetails = () => {
    const sidebar = document.querySelector('.sidebar-container');
    if (sidebar.offsetHeight == 0) {
      allDetailsArray = allDetailsArrayMobile;
      allDetails = allDetailsMobile;
    }
  }

  const refreshDetails = () => {
    defineAllDetails();
    let activeItem = document.querySelector('.active');
    let activeItemId = activeItem.getAttribute('data-target');
    let activeItemDetails;

    if (allDetails == allDetailsDesktop) {
      activeItemDetails = document.getElementById(`d${activeItemId}`);
    } else {
      activeItemDetails = document.getElementById(`m${activeItemId}`);
    }


    let otherDetails = allDetailsArray.filter(n => ![activeItemDetails].includes(n))

    activeItemDetails.classList.remove('d-none');
    otherDetails.forEach(function(detail) {
      detail.classList.add('d-none');
    });
  };

  refreshDetails();

  const mutationObserver = new MutationObserver(refreshDetails)

  carouselItems.forEach(function(item) {
    mutationObserver.observe( item, { attributes: true } );
  })

  window.addEventListener('resize', event => {
    refreshDetails();
  })
};

export { sidebarHome };
