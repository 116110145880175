const preventDownload = () => {
  // this will disable dragging of all images
  $("img").mousedown(function(e){
    e.preventDefault()
  });
  // this will disable dragging of all videos
  $("video").mousedown(function(e){
    e.preventDefault()
  });

  // this will disable right-click on all images
  $("img").on("contextmenu",function(e){
    return false;
  });
  // this will disable right-click on all images
  $("video").on("contextmenu",function(e){
    return false;
  });
};

export { preventDownload }
