const radioInputs = document.querySelector('.images-collection.form-collection');
const loadedMedia = [];

const buildLoadedMediaInput = (mediaToLoad) => {
  for (let i = 0; i < mediaToLoad.length; i++) {
    const mediaId = `${mediaToLoad[i].size}___${mediaToLoad[i].name.split(" ").join("")}`;

    const inputHTML = `<input class="loaded-media" type="hidden" name="more_views_names[]" id=${mediaId} value=${mediaId}>`
    radioInputs.insertAdjacentHTML('beforebegin', inputHTML);
  }
};

const buildRadioInput = (mediaHTML, mediaId) => {
  const url = window.location.pathname;
  let element
  if (url.includes('artworks') || url.includes('obras')) {
    element = "artwork"
  } else if (url.includes('artists') || url.includes('artistas')) {
    element = "artist"
  } else if (url.includes('exhibitions') || url.includes('exposicoes')) {
    element = "exhibition"
  } else if (url.includes('fairs') || url.includes('feiras')) {
    element = "fair"
  } else if (url.includes('edition') || url.includes('edicoes')) {
    element = "edition"
  };
  const radioInputHTML = `<div class="col-4 p-0 py-2 text-center loaded-media">
                            <input class="form-check-input is-valid radio_buttons required d-none" required="required" aria-required="true" type="radio" value="${mediaId}" name="${element}[featured_view_id]" id="${element}_featured_view_id_${mediaId}">
                            <label class="form-check-label collection_radio_buttons" for="${element}_featured_view_id_${mediaId}">
                              ${mediaHTML}
                            </label>
                          </div>`;
  return radioInputHTML;
};

const handleImage = (file) => {
  const reader = new FileReader();
  reader.onload = function(e) {
    loadedMedia.push(file);

    const mediaId = `${file.size}___${file.name.split(" ").join("")}`;

    const imageHTML = `<img class="added-media" src="${e.target.result}">`;
    const imageInputHTML = buildRadioInput(imageHTML, mediaId);
    radioInputs.insertAdjacentHTML('beforeend', imageInputHTML);
  }
  reader.readAsDataURL(file);
}

const handleVideo = (file) => {
  const reader = new FileReader();
  reader.onload = function(e) {
    loadedMedia.push(file);

    const blob = new Blob([reader.result], {type: file.type});
    const url = URL.createObjectURL(blob);
    const video = document.createElement('video');
    const timeupdate = () => {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
        video.pause();
      }
    };
    video.addEventListener('loadeddata', function() {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
      }
    });
    const snapImage = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL();
      const success = image.length > 100000;
      if (success) {
        const img = document.createElement('img');
        img.src = image;
        document.getElementsByTagName('div')[0].appendChild(img);
        URL.revokeObjectURL(url);
      }
      return success;
    };
    video.addEventListener('timeupdate', timeupdate);
    video.preload = 'metadata';
    video.src = url;
    video.classList.add('added-media');
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.play();

    const mediaId = `${file.size}___${file.name.split(" ").join("")}`;

    const videoInputHTML = buildRadioInput(video.outerHTML, mediaId);
    radioInputs.insertAdjacentHTML('beforeend', videoInputHTML);
  }
  reader.readAsArrayBuffer(file);
}

const resetUploadedFiles = () => {
  document.querySelectorAll('.loaded-media').forEach(e => e.parentNode.removeChild(e));
  loadedMedia.splice(0, loadedMedia.length);
}

const applyEventListeners = (uploadFilesInput) => {
  uploadFilesInput.addEventListener('change', event => {
    resetUploadedFiles();
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type.match('image')) {
        handleImage(file)
      } else {
        handleVideo(file)
      }
    }
    setTimeout(() => buildLoadedMediaInput(loadedMedia), 2000);
  });
}

// IMAGE MULTIPLE
const imagePreviewMultiple = () => {
  const uploadFilesMultipleInput = document.querySelector('.file-multiple input');
  applyEventListeners(uploadFilesMultipleInput);
}

// IMAGE SINGLE
const imagePreviewSingle = () => {
  const uploadFilesSingle = document.querySelector('.file-single');
  const uploadFilesSingleInput = document.querySelector('.file-single input');

  uploadFilesSingleInput.addEventListener('change', event => {
    resetUploadedFiles();
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = function(e) {
      const mediaId = `${file.size}___${file.name.split(" ").join("")}`;

      const imageHTML = `<img class="added-media loaded-media" src="${e.target.result}">`;
      uploadFilesSingle.insertAdjacentHTML('beforeend', imageHTML);
    }
    reader.readAsDataURL(file);
  })
}

// REMOVE VIEWS
const imageRemove = () => {
  const imagesToRemove = document.querySelectorAll('.image-remove');
  for (let i = 0; i < imagesToRemove.length; i++) {
    const removeId = imagesToRemove[i].parentElement.querySelector('.image-remove-id').innerText;
    imagesToRemove[i].addEventListener('click', event => {
      if (!imagesToRemove[i].classList.contains('selected')) {
        imagesToRemove[i].classList.toggle('selected');
        const inputHTML = `<input class="removed-media" type="hidden" name="removed_views_ids[]" id=${removeId} value=${removeId}>`
        radioInputs.insertAdjacentHTML('beforebegin', inputHTML);
      } else {
        imagesToRemove[i].classList.toggle('selected');
        document.querySelector(`.removed-media[id="${removeId}"]`).remove();
      }
    })
  }
}

export { imagePreviewSingle, imagePreviewMultiple, imageRemove }
