const collapseNavbar = () => {
  const container = document.querySelector('.navbar-container');
  const menu = document.querySelector('.navbar-menu');
  const toggler = container.querySelector('.navbar-toggler');

  const collapse = () => {
    if (menu.offsetHeight > 0) {
      toggler.click();
    }
  }

  const detectOutside = (clickTarget) => {
    let target = clickTarget;
    do {
      if (target == (container || menu)) {
        return;
      }
      target = target.parentNode;
    } while (target);
    collapse();
  }

  window.addEventListener('resize', event => {
    collapse();
  })

  window.addEventListener('click', event => {
    detectOutside(event.target);
  })

  window.addEventListener('touchstart', event => {
    detectOutside(event.target);
  })
}

export { collapseNavbar }
