const editionsFilter = () => {
  const toggleList = Array.from(document.querySelectorAll('.sidebar-link-toggle'));

  const allSections = Array.from(document.querySelectorAll('.masonry-edition'));

  toggleList.forEach(item => {
    item.addEventListener('click', event => {
      const sectionToggle = event.target
      const sectionToggleData = sectionToggle.getAttribute('data-target');

      const clickedSection = Array.from(document.getElementsByClassName(sectionToggleData));
      const otherSections = allSections.filter(n => !clickedSection.includes(n))

      toggleList.forEach(item => {
        item.classList.remove('active-link')
      })
      sectionToggle.classList.add('active-link');

      clickedSection.forEach(item => {
        item.classList.remove('masonry-none');
        item.classList.remove('masonry-hide');
      });

      otherSections.forEach(item => {
        item.classList.add('masonry-hide');
        const masonryNone = () => {
          item.classList.add('masonry-none');
        }
        setTimeout(masonryNone, 600)
      });
    });
  });
}

export { editionsFilter };
