const sidebarTagScroll = () => {

  // VARIABLES
  let photoDescriptionAll;
  let photoDescription;
  let container;
  let main;
  let navbar;
  let allSections;
  let visibleTop;
  let visibleBottom;
  let activeSection;
  let activeSectionTop;
  let activeSectionBottom;

  let sidebarTagListAll;
  let sidebarTagList;
  let controller;
  let action;

  const checkTagList = () => {
    sidebarTagListAll = document.querySelectorAll('.section-tag-list');
    if (sidebarTagListAll[0].getBoundingClientRect().height != 0) {
      sidebarTagList = sidebarTagListAll[0];
    } else {
      sidebarTagList = sidebarTagListAll[1];
    }
    controller = sidebarTagList.getAttribute('data-controller');
    action = sidebarTagList.getAttribute('data-action');
    activeSection = allSections[0];
  }

  const resetVariables = () => {
    photoDescriptionAll = document.querySelectorAll('.sidebar-footer-featured');
    if (photoDescriptionAll[0].getBoundingClientRect().height != 0) {
      photoDescription = photoDescriptionAll[0];
    } else {
      photoDescription = photoDescriptionAll[1];
    }
    container = document.querySelector('.overall-container');
    main = document.querySelector('.main-container');
    navbar = document.querySelector('.navbar-container');
    allSections = document.querySelectorAll('.main-section');
    visibleTop = navbar.offsetHeight;
    visibleBottom = main.offsetHeight + navbar.offsetHeight;
    checkTagList();
  }

  // CHANGE URL
  const changeUrl = (url, link) => {
    if (/---.*/.test(url)) {
      window.history.pushState('', '', url.substring(0, url.indexOf('---')) + `---${link}`);
    } else {
      window.history.pushState('', '', url + `---${link}`);
    }
  }

  // CHANGE VISIBLE SECTION
  const changeVisibleSection = (testedSection) => {
    activeSection = testedSection
    activeSectionTop = testedSection.getBoundingClientRect().top

    const activeSectionId = activeSection.getAttribute('id')
    const activeSectionLink = sidebarTagList.querySelector(`[data-target=${activeSectionId}]`)

    changeUrl(window.location.href, activeSectionId);

    // remove highlight from sections
    for (var i = sidebarTagList.querySelectorAll('.section-link').length - 1; i >= 0; i--) {
      sidebarTagList.querySelectorAll('.section-link')[i].classList.remove('active-link')
    }
    if (controller == 'artists' && activeSectionId != 'i' && photoDescription) {
      photoDescription.classList.add('sidebar-footer-hide')
    }
    // highlight visible section
    activeSectionLink.classList.add('active-link')
    if (controller == 'artists' && activeSectionId == 'i' && photoDescription) {
      photoDescription.classList.remove('sidebar-footer-hide')
    }
  }

  // CHECK VISIBLE SECTION
  const checkVisibleSections = () => {
    for (var i = allSections.length - 1; i >= 0; i--) {
      const testedSection = allSections[i]
      const testedSectionTop = testedSection.getBoundingClientRect().top
      const testedSectionBottom = testedSection.getBoundingClientRect().bottom

      activeSectionTop = activeSection.getBoundingClientRect().top;
      activeSectionBottom = activeSection.getBoundingClientRect().bottom;

      const activeDiffTop = activeSectionTop - visibleTop
      const activeDiffBottom = activeSectionBottom - visibleBottom

      const testedDiffTop = testedSectionTop - visibleTop
      const testedDiffBottom = testedSectionBottom - visibleBottom

      const testedHeaderVisible = testedDiffTop >= 0 && testedSectionTop + 18 <= visibleBottom;
      const activeHeaderUp = activeDiffTop < 0 && testedDiffTop > activeDiffTop;
      const activeHeaderDown = testedDiffTop < activeDiffTop;

      if (testedHeaderVisible && (activeHeaderUp || activeHeaderDown)) {
        changeVisibleSection(testedSection);
      }
    }
  }

  // NAVIGATE TO SECTION
  const navigateToSection = (sectionInfo) => {
    const section = document.getElementById(sectionInfo);
    const topPosition = section.offsetTop;
    container.scrollTo({ top: topPosition, behavior: 'smooth' });
    checkVisibleSections();
  }

  // CHECK CURRENT SIDEBAR SECTION (REDIRECT)
  const checkRedirect = (link) => {
    let sectionLinkData = link.getAttribute('data-target');
    let oldUrl = window.location.href;
    let suffix;

    if (action == 'artworks') {
      suffix = I18n.t('routes.artworks');
    } else if (action == 'exhibitions') {
      suffix = I18n.t('routes.exhibitions');
    } else if (action == 'fairs') {
      suffix = I18n.t('routes.fairs');
    } else if (action == 'news') {
      suffix = I18n.t('routes.news');
    }

    if (action != 'show' && window.location.href.indexOf(suffix) > -1) {
      let oldUrlEnd = '/' + suffix;
      let newUrl = oldUrl.substring(0, oldUrl.indexOf(oldUrlEnd))
      if (sectionLinkData != 'i') {
        newUrl += `---${sectionLinkData}`;
      }
      const navToNewUrl = window.open(newUrl, '_self');
    } else {
      changeUrl(oldUrl, sectionLinkData);
      navigateToSection(sectionLinkData);
    }
  }

  // CLICK SIDEBAR LINKS
  const clickSidebar = () => {
    for (var i = sidebarTagList.querySelectorAll('.section-link').length - 1; i >= 0; i--) {
      sidebarTagList.querySelectorAll('.section-link')[i].addEventListener('click', event => {
        checkRedirect(event.target);
      });
    }
  }

  // CHECK URL FOR SECTION DATA
  const checkUrl = () => {
    const url = window.location.href;
    if (/---.*/.test(url) && (controller == 'artists' || controller == 'exhibitions' || controller == 'fairs')) {
      const sectionUrl = url.split('---')[1];
      const findSectionLink = sidebarTagList.querySelector(`[data-target="${sectionUrl}"]`)
      for (var i = sidebarTagList.querySelectorAll('.section-link').length - 1; i >= 0; i--) {
        if (sidebarTagList.querySelectorAll('.section-link')[i] == findSectionLink) {
          navigateToSection(sectionUrl);
        }
      }
    }
  }

  // ON SCROLL
  const scrollEvent = () => {
    if (action == 'show' || action == 'dashboard') {
      container.addEventListener('scroll', event => {
        checkVisibleSections();
      })
    }
  }

  // INIT
  const initReset = () => {
    resetVariables();
    clickSidebar();
  }

  const initCheck = () => {
    scrollEvent();
    checkUrl();
  }

  initReset();
  initCheck();
  window.addEventListener('resize', event => {
    initReset();
  });
}

export { sidebarTagScroll };
